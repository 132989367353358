<template>
    <div class="share-compon">
        <h2 class="title">微在线 优惠领券</h2>
        <div class="content">
            <img class="bg" src="../assets/coupon_fen.png" />
            <img
                class="coupons coupon-1"
                @click="shareInput"
                src="../assets/coupon01.jpg"
                alt
                srcset
            />
            <!-- <a class="coupons coupon-2" href="javascript:void(0);"  @click="sharePaper">查看我的券</a> -->
        </div>
        <div class="bg-pos"></div>
        <div class="input-box" v-show="showInpt">
            <img class="close-btn" src="../assets/close.png" @click="showInpt=false" />
            <div class="input-box-inner">
                <div class="input-box-title">请输入您的姓名</div>
                <div class="input-box-input">
                    <input type="text" v-model="name" placeholder="姓名" />
                </div>
                <div class="input-box-title">请输入您的手机号码</div>
                <div class="input-box-input">
                    <input type="tel" v-model="phone" placeholder="手机号码" />
                </div>
                <div class="input-box-title">请输入您的收到的验证码</div>
                <div class="input-box-input">
                    <input type="text" v-model="code" placeholder="验证码" /> <a class="resend-btn" @click="resendCode" href="javascript:void(0);">发送验证码</a>
                </div>
                <div class="input-box-btn" @click="getCoupon">确定领券</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showInpt: false,
            giftid: 0,
            sharemenberid: 0,
            code:"",
            phone: "",
            name: "",
            qrcodeUrl: "",
        };
    },
    mounted() {
        this.getSearchParams();
        this.getLogin();
    },
    methods: {
        sharePaper(){
            if(this.qrcodeUrl == ""){
                alert("请先领取优惠券")
                return false;
            }
            this.$router.push({
                path:"/shareCouponQrcode"
            })
            
        },
        resendCode(){
            if(this.phone == ""){
                alert("请输入手机号码");
                return false;
            }

            this.wpost({
                url: '/Ajax/WordWelfare/getfindcode',
                data: {
                    phone: this.phone,
                },
                succ: (data) => {
                    alert("发送验证码成功")
                }
            })
        },
        getCoupon(){
            if(this.name == ""){
                alert("请输入您的姓名");
                return false;
            }
            if(this.phone == ""){
                alert("请输入手机号码");
                return false;
            }
            if(this.code == ""){
                alert("请输入验证码");
                return false;
            }
            if(this.gitfid == "" || this.sharemenberid == ""){
                alert("参数有误，请尝试重新通过二维码进入领取优惠券页面");
                return false;
            } 
            this.wpost({
                url: '/Ajax/WordWelfare/receivecoupon',
                data: {
                    giftid: this.giftid,
                    sharememberid: this.sharemenberid,
                    name: this.name,
                    findcode: this.code
                },
                succ: (data) => {
                    this.qrcodeUrl = data.model.Coupon_QrCodeUrl;
                    localStorage.setItem("coupon_qrcode", data.model.Coupon_QrCodeUrl);
                    alert("领取成功")
                    this.$router.push({
                        path:"/couponList/1"
                    })
                }
            })
        },
        getSearchParams() {
            let params = new URLSearchParams(location.href.split("?")[1]);
            console.log("params:", params);
            this.giftid = params.get("giftid");
            this.sharemenberid = params.get("sharememberid");
            console.log(this.gitfid, this.sharemenberid);
        },
        getLogin() {
            console.log(document.cookie);
            let cookieStr = document.cookie;
            console.log("indexOf:", cookieStr.indexOf("Member_ID"));
            if (cookieStr.indexOf("Member_ID") == -1) {
                this.wpost({
                    url: "/Ajax/WordWelfare/autologin",
                    data: {
                        RedirectUrl: "www.visasiv.cn/weilife/#/sharecoupon?giftid="+ this.gitfid +"&sharememberid="+ this.sharemenberid
                    },
                    succ: (data) => {
                        console.log("向后台发起登录跳转");
                    },
                });
                return false;
            }

            let cookieStrArr = cookieStr.split(";");
            let cookieObj = null;
            for (let i = 0; i < cookieStrArr.length; i++) {
                let temp = cookieStrArr[i].split("=");
                let keyName = temp[0].trim();
                if (keyName == "Member_ID") {
                    cookieObj = {
                        [keyName]: temp[1],
                    };
                }
            }
            console.log(cookieObj);
            localStorage.setItem("memberid", cookieObj.Member_ID);
        },
        shareInput() {
            this.showInpt = true;
        },
    },
};
</script>

<style scoped>
.close-btn{
    position: absolute;
    right: 6px;
    top: 6px;
    width: 30px;
    height: 30px;
}
.resend-btn{
    font-size: 12px;
    color: #999;
    margin-left: 6px;
    display: inline-block;
    font-weight: 600;
    text-decoration: none;
    width: 78px;
    line-height: 37px;
    color: #D9372C;
}
.input-box {
    position: fixed;
    top: 32vw;
    width: 98%;
    left: 1%;
    background: #f1f1f1;
    border-radius: 6px;
    z-index: 999;
    box-sizing: border-box;
    padding: 16px 22px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.input-box-title {
    font-size: 13px;
    color: #333;
    margin: 12px 0;
}
.input-box-input{
    display: flex;
    align-items: center;
    justify-content: flex-start;
}
.input-box-input input {
    outline: none;
    border: none;
    line-height: 37px;
    width: 100%;
    font-size: 15px;
    box-sizing: border-box;
    padding-left: 6px;
}
.input-box-btn {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    color: #fff;
    border-radius: 6px;
    margin-top: 22px;
    background-color: #D9372C;
}
.bg-pos {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    background-color: #86d2ec;
}

.share-compon {
    padding: 1px 0;
    min-height: calc(100vh - 170px);
    box-sizing: border-box;
    background-color: #86d2ec;
    background-image: url("../assets/coupon_bg.jpg");
    background-repeat: repeat-y;
    background-position: center bottom;
    background-size: 100% auto;
}
.share-compon h2 {
    text-align: center;
    color: #fff;
    margin: 30px 0;
    font-size: 27px;
    margin-top: 22vw;
    outline: #bebebe 2px;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.coupons {
    position: absolute;
    left: 16%;
    width: 66%;
}
.coupon-1 {
    top: 32vw;
}
.coupon-2 {
    bottom: 45vw;
    line-height: 47px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    background:#D9372C;
    border-radius: 2px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
}
.content {
    padding: 30px 12px;
    position: relative;
}
.content img.bg {
    width: 100%;
}
</style>